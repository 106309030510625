

import { Vue, Component } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import Add from './Add.vue'
import Detail from './Detail.vue'
import Head from '../Head.vue'
@Component({
  components: { Add, Detail, Head },
  filters: {
    repairState (val: any) {
      if (val === '1') {
        return '待维修'
      } else if (val === '2') {
        return '维保中'
      } else if (val === '3') {
        return '待确认'
      } else if (val === '4') {
        return '已完成'
      } else {
        return '已关闭'
      }
    }
  }
})
@KeepAlive
export default class InsectPest extends Vue {
  private addShow = false
  private detailShow = false
  // private endShow = false
  private headShow = false
  private goWhere = '' // true 确认报修，end 结束报修，forward 转修
  private maintainId = ''
  private auth = false
  private repairdTime = [] // 报修时间
  protected userId = '' // 当前登录用户id
  private searchInfo = {
    machineryName: '', // 机械名称
    repairNameCode: '', // 报修单编号/报修人
    startDate: '', // 报修时间开始
    endDate: '', // 报修时间结束
    repairState: '' // 报修单状态1待开始2待维修3待确认4结束5关闭
  }

  private repairStateList = [
    {
      value: '1',
      label: '待维修'
    },
    {
      value: '2',
      label: '维保中'
    },
    {
      value: '3',
      label: '待确认'
    },
    {
      value: '4',
      label: '已完成'
    },
    {
      value: '5',
      label: '已关闭'
    }
  ]

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  private personList = []

  get maintainIdQuery () {
    return this.$route.query.maintainId as string || ''
  }

  get machineryIdQuery () {
    return this.$route.query.machineryId as string || ''
  }

  created () {
    this.getHeadList()
    this.loadData()

    if (this.maintainIdQuery) {
      this.detailShow = true
      this.maintainId = this.maintainIdQuery
    }
    // 如果有 machineryId 则是从归还领用过来的, 打开新增弹窗
    if (this.machineryIdQuery) {
      // console.log('id存在')
      this.addShow = true
    }
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryRepairByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
      this.userId = this.$store.state.userInfo.userId
      this.tableData.forEach((item: any) => {
        item.auth = this.getAuth(item.projectId)
      })
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  success () {
    this.getHeadList()
    this.loadData()
  }

  // 去新增页面
  onAdd () {
    this.addShow = true
  }

  onDetail (item: any) {
    this.detailShow = true
    this.maintainId = item.maintainId
    this.auth = item.auth
  }

  onStaRepair (maintainId: any) {
    this.$confirm('确认开始维修吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.startYhMachineryRepair, { maintainId }).then(() => {
        // console.log('开始报修成功', res)
        this.$message.success('开始成功')
        this.loadData()
      })
    })
  }

  onClose (maintainId: any) {
    this.maintainId = maintainId
    this.detailShow = true
    this.goWhere = 'close'
  }

  onForward (maintainId: any) {
    // console.log('列表页this.goWhere', this.goWhere)
    // debugger
    // console.log('转办')
    this.maintainId = maintainId
    this.detailShow = true
    this.goWhere = 'forward'
  }

  onEnd (maintainId: any) {
    console.log('确认')
    this.maintainId = maintainId
    this.detailShow = true
    this.goWhere = 'end'
  }

  onTrue (maintainId: any) {
    console.log('列表页this.goWhere', this.goWhere)
    // debugger
    console.log('确认')
    this.maintainId = maintainId
    this.detailShow = true
    this.goWhere = 'true'
  }

  onDelete (maintainId: any) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.deleteYhMachineryRepair, { maintainId }).then(() => {
        // this.handleClose()
        this.$message.success('删除成功')
        this.loadData()
      })
    })
  }

  onHead () {
    this.headShow = true
  }

  selectTime1 (val: any) {
    // console.log('时间', val)
    if (!val) {
      this.searchInfo.startDate = ''
      this.searchInfo.endDate = ''
    } else {
      this.searchInfo.startDate = this.repairdTime[0]
      this.searchInfo.endDate = this.repairdTime[1]
    }
  }

  getAuth (id: string) {
    const item: any = this.personList.filter((item: any) => item.projectId === id)
    if (item[0]) {
      return item[0].userId === this.$store.state.userInfo.userId
    }
    return false
  }

  getHeadList () {
    this.$axios.get(this.$apis.machineryManage.selectYhRepairUserByList).then(res => {
      this.personList = res.list || []
    })
  }
}
