
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
@Component({
  components: { }
})

export default class extends Vue {
  @Prop() readonly closeShow!: boolean
  @Prop() readonly maintainId!: string

  @Watch('closeShow')
  istrue (val: string) {
    if (val) {
      this.formData.maintainId = this.maintainId
    }
  }

  private formData = {
    maintainId: '',
    confirmNotes: ''
  }

  private rules = {
    confirmNotes: [
      { required: true, message: '请输入备注信息', trigger: ['blur', 'change'] }
    ]
  }

  onSubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        this.$axios.post(this.$apis.machineryManage.closeYhMachineryRepair, this.formData).then(() => {
          this.$message.success('关闭成功')
          this.handleClose()
          this.$emit('success')
        })
      }
    })
  }

  handleClose () {
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:closeShow', false)
    this.formData = {
      maintainId: '',
      confirmNotes: ''
    }
  }
}
