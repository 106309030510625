
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
@Component({
  components: { }
})

export default class extends Vue {
  @Prop() readonly forwardShow!: boolean
  @Prop() readonly maintainId!: string
  @Prop() readonly projectId!: string

  @Watch('forwardShow')
  istrue (val: string) {
    if (val) {
      this.formData.maintainId = this.maintainId
    }
  }

  @Watch('projectId')
  istrue2 (val: string) {
    if (val) {
      this.getUserList(this.projectId)
    }
  }

  private formData = {
    maintainId: '',
    recipient: '', // 接修人
    confirmNotes: ''
  }

  private userNameList = []

  private rules = {
    recipient: [
      { required: true, message: '请选择接修人', trigger: ['blur', 'change'] }
    ],
    confirmNotes: [
      { required: true, message: '请输入备注信息', trigger: ['blur', 'change'] }
    ]
  }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.userNameList = res || []
    })
  }

  onSubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        this.$axios.post(this.$apis.machineryManage.forwardYhMachineryRepair, this.formData).then(() => {
          this.$message.success('转办成功')
          this.handleClose()
          this.$emit('success')
        })
      }
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:forwardShow', false)
    this.formData = {
      maintainId: '',
      recipient: '', // 接修人
      confirmNotes: ''
    }
  }
}
