
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class FileType extends Vue {
  @Prop({ default: false }) private headShow!: boolean
  @Prop() readonly status!: string // status为 repair 是报修，否则为保养
  private projectList = []
  private userNameList = [] // 用户名列表
  private addLine: number | null = null // 当前操作行
  private tableData: any = []
  private loading = false
  private info= {
    projectId: '',
    userId: '',
    userName: ''
  }

  private title = '' // 标题
  @Watch('headShow')
  onShowChange (value: boolean) {
    // console.log('value', value)
    if (value) {
      this.getHeadList()
      if (this.status === 'repair') {
        this.title = '机械报修负责人确认'
        // console.log('报修')
      } else {
        this.title = '机械保养负责人确认'
        // console.log('保养')
      }
    }
  }

  @Watch('info.projectId')
  projectIdChange (value: any) {
    // console.log('项目id2', value)
    this.getUserList(value)
    // this.info.userId = ''
  }

  created () {
    this.getProjectList()
    // this.getUserList()
  }

  getHeadList () {
    this.loading = true
    const url = this.status === 'repair' ? this.$apis.machineryManage.selectYhRepairUserByList : this.$apis.machineryManage.selectYhMaintenanceUserByList
    this.$axios.get(url).then(res => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectYhProjectByList).then(res => {
      // console.log('getProjectList', res)
      this.projectList = res
      // 已经出现在负责人列表的项目给禁用掉或者不让出现在列表中
    })
  }

  // 每个项目不重复
  getExtraList (rowIndex: number) {
    const list: any[] = []
    this.tableData.forEach((item: any, index: any) => {
      if (rowIndex !== index) {
        list.push(item.projectId)
      }
    })
    return this.projectList.filter((item: any) => {
      return list.every((item1) => {
        return item.projectId !== item1
      })
    })
  }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.userNameList = res || []
    })
  }

  // getUserList () {
  //   this.$axios.get(this.$apis.personnel.personnelPage, {
  //     page: '',
  //     size: ''
  //   }).then((res) => {
  //     // console.log('人员列表', res)
  //     this.userNameList = res.list
  //   })
  // }

  addUpdate (index: number, row: any) {
    console.log('add', index, row)
    // 保存
    if (this.addLine === index) {
      if (!this.info.projectId) {
        this.$message.warning('请选择项目')
        return
      }
      if (!this.info.userId) {
        this.$message.warning('请选择负责人')
        return
      }
      let url = ''
      if (this.status === 'repair') {
        url = this.info.userName ? this.$apis.machineryManage.updateYhRepairUser : this.$apis.machineryManage.insertYhRepairUser
      } else {
        url = this.info.userName ? this.$apis.machineryManage.updateYhMaintenanceUser : this.$apis.machineryManage.insertYhMaintenanceUser
      }
      this.$axios.post(url, this.info).then(() => {
        this.$message.success('操作成功')
        this.$emit('success')
        this.addLine = null
        this.info = {
          projectId: '',
          userId: '',
          userName: ''
        }
        this.getHeadList()
      })
    } else if (this.addLine !== null) {
      this.$message.warning('请先保存')
    } else {
      // console.log('编辑', row)
      // 点击编辑
      this.addLine = index
      this.info = { ...row }
    }
  }

  onCancel (value: any) {
    this.addLine = null
    value === 'delete' && this.tableData.pop(this.info)
  }

  addFormNew () {
    // console.log('this.addLine', this.addLine)
    if (!this.addLine && this.addLine !== 0) {
      // this.info = JSON.parse(JSON.stringify(this.initAddForm))
      this.info = {
        projectId: '',
        userId: '',
        userName: ''
      }
      this.tableData.push(this.info)
      this.addLine = this.tableData.length - 1
      // 滚动到表格最底部
      this.$nextTick(() => {
        (this.$refs.addTable as any).bodyWrapper.scrollTop = (this.$refs.addTable as any).bodyWrapper.scrollHeight
      })
    } else {
      this.$message.warning('请先保存')
    }
  }

  // 参数没有改变
  onDelete (row: any) {
    this.$confirm('是否确认删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      const url = this.status === 'repair' ? this.$apis.machineryManage.deleteDataYhRepairUser : this.$apis.machineryManage.deleteDataYhMaintenanceUser
      this.$axios.post(url,
        {
          projectId: row.projectId
        }).then(() => {
        this.$message.success('删除成功')
        this.addLine = null
        this.getHeadList()
      })
    })
  }

  close () {
    // 数据清除
    this.addLine = null
    this.info = {
      projectId: '',
      userId: '',
      userName: ''
    }
    // this.$emit('onClose')
    this.$emit('update:headShow', false)
  }
}
