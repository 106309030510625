
import { ElForm } from 'element-ui/types/form'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { MachineRepair } from '@/types/machineryManage'
@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})

export default class extends Vue {
  @Prop() readonly addShow!: boolean

  @Watch('addShow')
  isAdd (val: string) {
    if (val) {
      this.formData.repairName = this.userInfo.userName
      this.formData.repairDept = this.userInfo.deptName
    }
  }

  private rules = {
    machineryType: [
      { required: true, message: '请选择机械类型', trigger: ['blur', 'change'] }
    ],
    machineryId: [
      { required: true, message: '请选择机械名称', trigger: ['blur', 'change'] }
    ],
    repairFault: [
      { required: true, message: '请输入故障反馈', trigger: ['blur'] }
    ]
  }

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']

  private formData: MachineRepair = {
    // 提交表单字段
    machineryId: '', // 机械ID
    recordId: '', // 领用单id，从归还机械过来就传，不是就不传
    repairDept: '', // 报修部门(当前用户部门)
    repairLocation: '', // 报修地点
    repairFault: '', // 故障反馈
    faultList: [], // 故障图片

    // 展示字段，不提交表单
    machineryType: '', // 机械类型
    machineryCode: '', // 机械编号
    machineryBrand: '', // 机械品牌
    machineryModel: '', // 机械型号
    userName: '', // 机械管家
    recordUserName: '', // 领用人
    maintainId: '系统自动生成',
    repairName: ''// 报修人(当前用户)
  }

  private machineryTypeList = [] // 机械分类列表
  private checkedMachineryList = [] //  机械名称

  created () {
    // machineryId存在 表名是从归还报修过来的
    if (this.$route.query.machineryId) {
      this.initData()
    // 否则就是正常的新增报修
    } else {
      this.getMachineryTypeList()
    }
  }

  get userInfo () {
    return this.$store.state.userInfo
  }

  initData () {
    const routeQuery = this.$route.query
    this.formData.recordId = routeQuery.recordId as string || ''
    this.formData.recordUserName = routeQuery.recordUserName as string || ''
    this.formData.repairName = this.userInfo.userName
    this.formData.repairDept = this.userInfo.deptName

    // 先渲染机械分类列表
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'machineryType' }).then(res => {
      this.machineryTypeList = res.machineryType || []
      this.formData.machineryType = routeQuery.machineryType as string

      // 再根据分类渲染机械名称列表
      this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByPage, { machineryType: routeQuery.machineryType }).then(res => {
        this.checkedMachineryList = res.list || {}
        // 给 machineryId、编号、品牌、型号、管家、领用人赋值
        const machinery: any = this.checkedMachineryList.find((item: any) => item.machineryId === routeQuery.machineryId)
        this.formData.machineryId = machinery.machineryId
        this.formData.machineryCode = machinery.machineryCode
        this.formData.machineryBrand = machinery.machineryBrand
        this.formData.machineryModel = machinery.machineryModel
        this.formData.userName = machinery.userName
      })
    })
  }

  // 机械分类
  getMachineryTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'machineryType' }).then(res => {
      console.log('分类', res)
      this.machineryTypeList = res.machineryType || []
    })
  }

  // 选择机械分类
  selectMachineryType (val: any) {
    console.log('选择机械类型', val)
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByPage, { machineryType: val }).then(res => {
      // console.log('列表', res)
      // 重置机械名称列表
      this.checkedMachineryList = res.list || {}
      this.formData.machineryId = ''
      // 清空编号、品牌、型号、管家、领用人
      this.formData.machineryCode = ''
      this.formData.machineryBrand = ''
      this.formData.machineryModel = ''
      this.formData.userName = ''
      this.formData.recordUserName = ''
      this.formData.recordId = ''
    })
  }

  // 选择机械名称
  selectMachineryName (val: any) {
    const machinery: any = this.checkedMachineryList.find((item: any) => item.machineryId === val)
    // console.log('machinery', machinery)
    if (machinery.machineryState === '3') {
      this.$message.warning('当前机械处在待维修状态，无法新增报修')
      this.formData.machineryId = ''
      return
    } else if (machinery.machineryState === '4') {
      this.$message.warning('当前机械正在维保中，无法新增报修')
      this.formData.machineryId = ''
      return
    } else if (machinery.machineryState === '5') {
      this.$message.warning('当前机械已终止使用，无法新增报修')
      this.formData.machineryId = ''
      return
    }
    // 给编号、品牌、型号、管家、领用人赋值
    this.formData.machineryCode = machinery.machineryCode
    this.formData.machineryBrand = machinery.machineryBrand
    this.formData.machineryModel = machinery.machineryModel
    this.formData.userName = machinery.userName
    this.formData.recordUserName = machinery.recordUserName ? machinery.recordUserName : '--'
    this.formData.recordId = machinery.recordId
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this.formData as MachineRepair).faultList.push(file)
  }

  imgRemove (index: number) {
    this.formData.faultList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        console.log('提交数据', this.formData)
        const params = {
          machineryId: this.formData.machineryId, // 机械ID
          recordId: this.formData.recordId, // 领用单id，从归还机械过来就传，不是就不传
          repairDept: this.formData.repairDept, // 报修部门(当前用户部门)
          repairLocation: this.formData.repairLocation, // 报修地点
          repairFault: this.formData.repairFault, // 故障反馈
          faultList: this.formData.faultList // 故障图片
        }
        console.log('log', params)
        this.$axios.post(this.$apis.machineryManage.insertYhMachineryRepair, params).then(() => {
          this.$message.success('新增成功')
          // this.$emit('closeDialog')
          this.handleClose()
          this.$emit('success')
          this.$router.push({ name: 'repair' })
        })
      }
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.clearData();
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:addShow', false)
  }

  // 清空数据
  clearData () {
    this.checkedMachineryList = [] //  机械名称
    this.formData = {
      // 提交表单字段
      machineryId: '', // 机械ID
      recordId: '', // 领用单id，从归还机械过来就传，不是就不传
      repairDept: '', // 报修部门(当前用户部门)
      repairLocation: '', // 报修地点
      repairFault: '', // 故障反馈
      faultList: [], // 故障图片

      // 展示字段，不提交表单
      machineryType: '', // 机械类型
      machineryCode: '', // 机械编号
      machineryBrand: '', // 机械品牌
      machineryModel: '', // 机械型号
      userName: '', // 机械管家
      recordUserName: '', // 领用人
      maintainId: '系统自动生成',
      repairName: ''// 报修人(当前用户)
    }
  }
}
