
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { MachineRepairEnd, MachineRepairItem } from '@/types/machineryManage'
@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})

export default class extends Vue {
  @Prop() readonly endShow!: boolean
  @Prop() readonly maintainId!: string
  @Prop() readonly projectId!: string

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']
  protected loading = false
  private userList = []

  private formData: MachineRepairEnd ={
    maintainId: '', // 报修单ID
    repairInfo: '', // 维修基本信息
    repairSummary: '', // 维修信息汇总
    userIdList: [], // 维修机械师
    annexList: [], // 保养配件
    materialList: [], // 保养耗材
    repairList: [] // 维修图片
  }

  protected isCanAdd = true // 是否可以添加配件信息
  private annexList: MachineRepairItem= { // 配件信息
    maintainId: '',
    annexName: '',
    annexModel: '',
    annexNum: '',
    annexNotes: ''
  }

  protected isCanAdd2 = true // 是否可以添加耗材信息
  private materialList: MachineRepairItem= { // 耗材信息
    maintainId: '',
    annexName: '',
    annexModel: '',
    annexNum: '',
    annexNotes: ''
  }

  private rules = {
    repairInfo: [
      { required: true, message: '请输入维修基本信息', trigger: ['blur', 'change'] }
    ],
    userIdList: [
      { required: true, message: '请选择机械师', trigger: ['blur', 'change'] }
    ]
  }

  get machineryInfo () {
    // console.log('变化')
    return this.$store.state.MachineryInfo
  }

  @Watch('endShow')
  isDetail (val: string) {
    if (val) {
      this.formData.maintainId = this.maintainId
    }
  }

  @Watch('projectId')
  istrue2 (val: string) {
    // console.log('结束维修projectId', val)
    if (val) {
      this.getUserList(this.projectId)
    }
  }

  created () {
    // console.log('结束维修,', this.maintainId)
    // this.getUserList()
  }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.userList = res || []
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this.formData as MachineRepairEnd).repairList.push(file)
  }

  imgRemove (index: number) {
    this.formData.repairList.splice(index, 1)
  }

  addFormNew () {
    if (this.isCanAdd) {
      this.formData.annexList.push(this.annexList)
      this.isCanAdd = false
      this.$nextTick(() => {
        // 滚动到表格最底部
        (this.$refs.addTable as any).bodyWrapper.scrollTop = (this.$refs.addTable as any).bodyWrapper.scrollHeight
      })
    } else {
      this.$message.warning('请先保存')
    }
  }

  onSave (index: any) {
    // console.log(this.formData.annexList, index)
    if (!this.annexList.annexName || !this.annexList.annexModel) {
      this.$message.warning('配件名称、编号/型号不为空')
    } else if (this.annexList.annexNum < 1 || this.annexList.annexNum > 999) {
      this.$message.warning('数量为1到999')
    } else {
      this.formData.annexList[index].maintainId = true
      this.annexList = { // 配件信息
        maintainId: '',
        annexName: '',
        annexModel: '',
        annexNum: '',
        annexNotes: ''
      }
      this.isCanAdd = true
    }
  }

  onDelete (index: any, row: any) {
    console.log(row)
    this.$confirm('是否确认删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.formData.annexList.splice(index, 1)
    })
  }

  addFormNew2 () {
    if (this.isCanAdd2) {
      this.formData.materialList.push(this.materialList)
      this.isCanAdd2 = false
      this.$nextTick(() => {
        // 滚动到表格最底部
        (this.$refs.addTable2 as any).bodyWrapper.scrollTop = (this.$refs.addTable2 as any).bodyWrapper.scrollHeight
      })
    } else {
      this.$message.warning('请先保存')
    }
  }

  onSave2 (index: any) {
    // console.log(this.formData.materialList, index)
    if (!this.materialList.annexName || !this.materialList.annexModel) {
      this.$message.warning('耗材名称、编号/型号不为空')
    } else if (this.materialList.annexNum < 1 || this.materialList.annexNum > 999) {
      this.$message.warning('数量为1到999')
    } else {
      this.formData.materialList[index].maintainId = true
      this.materialList = { // 配件信息
        maintainId: '',
        annexName: '',
        annexModel: '',
        annexNum: '',
        annexNotes: ''
      }
      this.isCanAdd2 = true
    }
  }

  onDelete2 (index: any, row: any) {
    console.log(row)
    this.$confirm('是否确认删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.formData.materialList.splice(index, 1)
    })
  }

  onSubmit (upkeep: any) {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        // console.log('upkeep', upkeep)
        if (!this.isCanAdd) {
          this.$message.warning('请先保存配件信息')
          return
        }
        if (!this.isCanAdd2) {
          this.$message.warning('请先保存耗材信息')
          return
        }
        // 处理配件信息 (去掉 maintainId 字段)
        let annexList = []
        annexList = this.formData.annexList.map((item: any) => {
          return {
            annexModel: item.annexModel,
            annexName: item.annexName,
            annexNotes: item.annexNotes,
            annexNum: item.annexNum
          }
        })
        this.formData.annexList = annexList
        // 处理耗材信息 (去掉 maintainId 字段)
        let materialList = []
        materialList = this.formData.materialList.map((item: any) => {
          return {
            annexModel: item.annexModel,
            annexName: item.annexName,
            annexNotes: item.annexNotes,
            annexNum: item.annexNum
          }
        })
        this.formData.materialList = materialList
        // 发起请求
        this.$axios.post(this.$apis.machineryManage.endYhMachineryRepair, this.formData).then(() => {
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('success')
          // console.log('upkeep', upkeep)
          // 如果需要保养就跳保养页面
          if (upkeep === 'goUpkeep') {
            this.$router.push(
              {
                path: '/machineryManage/upkeep',
                query: {
                  machineryType: this.machineryInfo.machineryType,
                  machineryId: this.machineryInfo.machineryId
                }
              })
          } else {
            this.$router.push({ name: 'repair' })
          }
        })
      }
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:endShow', false)
    this.formData = {
      maintainId: '', // 报修单ID
      repairInfo: '', // 维修基本信息
      repairSummary: '', // 维修信息汇总
      userIdList: [], // 维修机械师
      annexList: [], // 保养配件
      materialList: [], // 保养耗材
      repairList: [] // 维修图片
    }
    this.isCanAdd = true
    this.annexList = { // 配件信息
      maintainId: '',
      annexName: '',
      annexModel: '',
      annexNum: '',
      annexNotes: ''
    }
    this.isCanAdd2 = true
    this.materialList = { // 耗材信息
      maintainId: '',
      annexName: '',
      annexModel: '',
      annexNum: '',
      annexNotes: ''
    }
  }
}
