
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
@Component({
  // name: 'machineryDetail_base'
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})

export default class extends Vue {
  @Prop() readonly basicInfo!: boolean

  private formData = {}
  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']

  @Watch('basicInfo')
  isDetail () {
    this.formData = this.basicInfo
  }
}
