
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import basicInfo from './Base.vue'
import End from './EndRepair.vue'
import True from './TrueRepair.vue'
import Forward from './ForwardRepair.vue'
import Close from './CloseRepair.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { basicInfo, End, True, Forward, Close },
  filters: {
    flowName (val: any) {
      if (val === '1') {
        return '报修'
      } else if (val === '2') {
        return '开始维修'
      } else if (val === '3') {
        return '结束维修'
      } else if (val === '4') {
        return '负责人确认'
      } else if (val === '5') {
        return '转办'
      } else {
        return '关闭'
      }
    },
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})

export default class extends Vue {
  @Prop() readonly detailShow!: boolean
  @Prop() readonly maintainId!: string
  @Prop() readonly auth!: boolean
  @Prop() readonly goWhere!: string
  // 记录类型1报修2开始维修/保养3结束维修/保养4确认5转办6关闭
  private locStyle = {
    1: 'step_init',
    2: 'step_start',
    3: 'step_end',
    4: 'step_true',
    5: 'step_foword',
    6: 'step_close'
  }

  private locStyle2 = {
    2: 'line_start',
    3: 'line_end',
    4: 'line_true',
    5: 'line_foword',
    6: 'line_close'
  }

  private formData = {
    flowList: [],
    projectId: ''
  }

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']

  private activeName = 'first'
  protected loading = false
  protected userId = false // 判断当前用户是否有操作按钮权限
  private endShow = false // 结束
  private trueShow = false // 确认
  private forwardShow = false // 转办
  private closeShow = false // 关闭
  private repairHeadName: any = '' // 确认负责人姓名

  @Watch('detailShow', { immediate: true })
  isDetail (val: string) {
    val && this.getDetail()
  }

  onSuccess () {
    console.log('成功')
    this.$emit('update:goWhere', '')
    this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryRepairByMaintainId, { maintainId: this.maintainId }).then(res => {
      console.log('getDetail', this.goWhere)
      // console.log('详情', res)
      this.formData = res || {}
      if (this.goWhere === 'forward') {
        this.$nextTick(() => {
          this.forwardShow = true
        })
      } else if (this.goWhere === 'end') {
        this.$nextTick(() => {
          this.endShow = true
        })
      } else if (this.goWhere === 'true') {
        this.$nextTick(() => {
          this.trueShow = true
        })
      } else if (this.goWhere === 'close') {
        this.$nextTick(() => {
          this.closeShow = true
        })
      }

      // 存入vuex,调整到新增保养页面使用
      this.$store.commit('updateMachineryInfo', this.formData)
      // 拿到确认负责人的姓名,后端不能直接返回，我们需要做处理
      let trueFlow: any = {
        userName: ''
      }
      trueFlow = this.formData?.flowList.find((item: any) => item.flowType === '4')
      if (trueFlow) {
        this.repairHeadName = trueFlow.userName
      }
      // 当前用户等于接修人，有按钮操作权限，否则没有
      if (this.$store.state.userInfo.userId === res.recipient) {
        this.userId = true
      } else {
        this.userId = false
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onStaRepair () {
    this.$confirm('确认开始维修吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.startYhMachineryRepair, { maintainId: this.maintainId }).then(res => {
        // console.log('开始报修成功', res)
        this.$message.success('开始成功')
        this.getDetail()
        this.$emit('success')
      })
    })
  }

  onForward () {
    this.forwardShow = true
  }

  onEnd () {
    this.endShow = true
  }

  onTrue () {
    this.trueShow = true
  }

  onClose () {
    this.closeShow = true
  }

  onDelete () {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.deleteYhMachineryRepair, { maintainId: this.maintainId }).then(() => {
        this.handleClose()
        this.$message.success('删除成功')
      })
    })
  }

  handleClose () {
    // console.log('关闭详情弹窗')
    this.$emit('update:detailShow', false)
    this.$emit('update:goWhere', '')
    this.formData = { flowList: [], projectId: '' }
    this.activeName = 'first'
    this.repairHeadName = ''
    this.$emit('success')
  }
}
